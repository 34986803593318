import React from "react";

import { makeStyles } from "@mui/styles";
import { Icon, Typography } from "@mui/material";

const useStyles = makeStyles({
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "24px 0",
  },
});

const HeaderComponent = (props: any) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Icon fontSize="large">{props.icon}</Icon>
      <Typography
        variant="h4"
        sx={{
          color: props.invertColor ? "white" : "black",
          fontFamily: "monospace",
          fontWeight: 500,
          letterSpacing: ".3rem",
          textTransform: "uppercase",
          marginLeft: 2,
        }}
      >
        {props.title}
      </Typography>
    </div>
  );
};

export default HeaderComponent;
