import * as React from "react";

import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export default function FloatingActionButtonSize() {
  return (
    <a href="https://wa.me/5548996523066">
      <Box
        sx={{
          "& > :not(style)": { m: 3, position: "fixed", bottom: 1, right: 1 },
        }}
      >
        <Fab
          size="large"
          sx={{ color: "white", backgroundColor: "#25d366 !important" }}
          aria-label="contact"
        >
          <WhatsAppIcon fontSize="large" />
        </Fab>
      </Box>
    </a>
  );
}
