import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Grid, Icon, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PlaceIcon from "@mui/icons-material/Place";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "48px",
    paddingBottom: "48px",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    paddingBottom: 12,
    [theme.breakpoints.down("lg")]: {
      justifyContent: "left",
    },
  },
  image: {
    width: "100%",
    height: "auto",
    borderRadius: 8,
  },
  button: {
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  subtitle: {
    textAlign: "right",
    [theme.breakpoints.down("lg")]: {
      textAlign: "left",
    },
  },
  timeWrapper: {
    display: "flex",
    alignItems: "center",
    padding: "18px 0",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "right",
    },
  },
}));

const RightItem = (props: any) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container spacing={4} className={classes.container}>
      <Grid item xs={12} lg={4} order={{ xs: 2, lg: 1 }}>
        <img className={classes.image} alt={props.title} src={props.image} />
      </Grid>
      <Grid item xs={12} lg={8} order={{ xs: 1, lg: 2 }}>
        <div className={classes.wrapper}>
          <div>
            <div className={classes.header}>
              <Icon fontSize="large">
                <PlaceIcon fontSize="large" sx={{ color: "#ea4335" }} />
              </Icon>
              <Typography variant="h4" sx={{ color: "#ea4335" }}>
                {props.title}
              </Typography>
            </div>
            <Typography
              variant="h6"
              className={classes.subtitle}
              sx={{
                color: "#303030",
                fontWeight: "normal",
              }}
            >
              {props.subtitle}
            </Typography>
          </div>
          {props.time && (
            <div className={classes.timeWrapper}>
              <Icon>
                <AccessTimeFilledIcon sx={{ color: "#3b3b3b" }} />
              </Icon>
              <Typography sx={{ paddingLeft: "4px", color: "#3b3b3b" }}>
                {props.time}
              </Typography>
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
            }}
          >
            <Button
              startIcon={<EventAvailableIcon />}
              className={classes.button}
              href="https://wa.me/5548996523066"
              size="large"
              variant="outlined"
            >
              {t("item.reserve")}
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default RightItem;
