import React from "react";
import { ThemeProvider, createTheme } from "@mui/material";

import HomePage from "./pages/Home";

const theme = createTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <HomePage />
    </ThemeProvider>
  );
}

export default App;
