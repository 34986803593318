import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// Languages
import en from "./translations/en.json";
import es from "./translations/es.json";
import pt from "./translations/pt.json";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "pt",
    // lng: "pt",
    resources: {
      en: {
        translation: {
          ...en,
        },
      },
      es: {
        translation: {
          ...es,
        },
      },
      pt: {
        translation: {
          ...pt,
        },
      },
    },
  });

export default i18n;
