import React from "react";
import { useTranslation } from "react-i18next";

import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";

import TourIcon from "@mui/icons-material/Tour";

import HeaderComponent from "./Header";
import TourCardComponent from "./TourCard";

import { Carousel } from "./Carousel";

import background from "../assets/images/background.png";
import betocarrero from "../assets/images/betocarrero.jpeg";
import blumenau from "../assets/images/blumenau.jpeg";
import campeche from "../assets/images/campeche.jpeg";
import centrohistorico from "../assets/images/centrohistorico.jpeg";
import pontehercilio from "../assets/images/pontehercilio.jpeg";

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "96vh",
    padding: "18px 0",
    background: "#1f2224",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  carousel: {
    display: "flex",
    width: "100%",
    cursor: "grab",
    alignItems: "center",
    justifyContent: "center",

    "& .slick-prev": {
      left: "-15px",
    },

    "& .slick-next": {
      right: "-15px",
    },

    "& .slick-track": {
      display: "flex",
      gap: "2em",

      "@media (max-width: 768px)": {
        gap: "1em",
      },
    },
  },
  item: {
    display: "flex",
    width: "100%",
  },
  card: {
    maxWidth: "400px",
    margin: "auto",
  },
});

const ToursComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.container} style={{ display: "flex" }}>
      <HeaderComponent
        title={t("appbar.tours")}
        icon={<TourIcon fontSize="large" sx={{ color: "white" }} />}
        invertColor={true}
      />
      <Container
        maxWidth="xl"
        sx={{
          padding: "12px",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <Carousel className={classes.carousel}>
          <div className={classes.item}>
            <div className={classes.card}>
              <TourCardComponent
                image={betocarrero}
                title={t("tours.betoCarrero.title")}
                description={t("tours.betoCarrero.description")}
              />
            </div>
          </div>
          <div className={classes.item}>
            <div className={classes.card}>
              <TourCardComponent
                image={blumenau}
                title={t("tours.oktoberfest.title")}
                description={t("tours.oktoberfest.description")}
              />
            </div>
          </div>
          <div className={classes.item}>
            <div className={classes.card}>
              <TourCardComponent
                image={campeche}
                title={t("tours.campeche.title")}
                description={t("tours.campeche.description")}
              />
            </div>
          </div>
          <div className={classes.item}>
            <div className={classes.card}>
              <TourCardComponent
                image={centrohistorico}
                title={t("tours.historicCenter.title")}
                description={t("tours.historicCenter.description")}
              />
            </div>
          </div>
          <div className={classes.item}>
            <div className={classes.card}>
              <TourCardComponent
                image={pontehercilio}
                title={t("tours.bridgeHercilio.title")}
                description={t("tours.bridgeHercilio.description")}
              />
            </div>
          </div>
        </Carousel>
      </Container>
    </div>
  );
};

export default ToursComponent;
