import React from "react";
import { useTranslation } from "react-i18next";

import { makeStyles, styled } from "@mui/styles";
import { Button, ButtonProps, Typography } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import AppBar from "../components/AppBar";
import DestinationsComponent from "../components/Destinations";
import ToursComponent from "../components/Tours";

import florianopolis from "../assets/images/florianopolis.jpeg";
import logo from "../assets/images/logo.png";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    height: "100vh",
  },
  background: {
    background: `url(${florianopolis})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: -2,
  },
  overlay: {
    background: "black",
    opacity: 0.6,
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: -1,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "90vh",
    padding: "0 24px",
    gap: "16px",
  },
  text: {
    opacity: 1,
    maxWidth: "600px",
  },
}));

const WhatsappButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: "#25d366 !important",
}));

const HomePage = () => {
  const { t } = useTranslation();

  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div
          style={{ backgroundAttachment: iOS() ? "scroll" : "fixed" }}
          className={classes.background}
        />
        <div className={classes.overlay} />
        <AppBar />
        <div className={classes.container}>
          <img style={{ width: "224px" }} src={logo} alt="logo" />
          <Typography
            variant="h6"
            className={classes.text}
            sx={{ color: "#cbcbcb", textAlign: "center" }}
          >
            {t("home.welcome")}
          </Typography>
          <WhatsappButton
            startIcon={<WhatsAppIcon />}
            variant="contained"
            size="large"
            href="https://wa.me/5548996523066"
          >
            {t("home.whatsapp")}
          </WhatsappButton>
        </div>
      </div>
      <span id="destinations">
        <DestinationsComponent />
      </span>
      <span id="tours">
        <ToursComponent />
      </span>
    </>
  );
};

export default HomePage;
