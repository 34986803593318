import React from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import { Button, Typography } from "@mui/material";

const useStyles = makeStyles({
  container: {
    backgroundColor: "white",
    width: "100%",
    height: "480px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
  },
  image: {
    borderRadius: "12px 12px 0 0",
    width: "100%",
    height: "200px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
  },
  wrapper: {
    padding: "12px",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  titleWrapper: {
    borderBottom: "2px solid #ea4335",
  },
});

const TourCardComponent = (props: any) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img className={classes.image} src={props.image} alt={props.title} />
      <div className={classes.wrapper}>
        <Typography variant="h5">
          <span className={classes.titleWrapper}>{props.title}</span>
        </Typography>
        <Typography variant="body1" sx={{ color: "#3b3b3b" }}>
          {props.description}
        </Typography>
        <Button
          variant="contained"
          size="large"
          href="https://wa.me/5548996523066"
          sx={{ width: "100%", backgroundColor: "#ea4335" }}
        >
          {t("tours.interestBtn")}
        </Button>
      </div>
    </div>
  );
};

export default TourCardComponent;
