import React from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import { Container, Divider } from "@mui/material";

import HeaderComponent from "./Header";
import LeftItem from "./LeftItem";
import RightItem from "./RightItem";

import florianopolis from "../assets/images/florianopolis2.jpeg";
import balneario from "../assets/images/balneario.webp";
import blumenau from "../assets/images/blumenau.jpeg";
import garopaba from "../assets/images/garopaba.png";
import bombinhas from "../assets/images/bombinhas.jpeg";
import itapema from "../assets/images/itapema.jpeg";

const useStyles = makeStyles({
  container: {
    width: "100%",
    background: "#ffffff",
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "18px 0",
  },
});

const DestinationsComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Container maxWidth="xl">
        <HeaderComponent
          icon={<FollowTheSignsIcon fontSize="large" />}
          title={t("appbar.destinations")}
        />
        <LeftItem
          title="Florianópolis, SC"
          subtitle={t("florianopolis.description")}
          image={florianopolis}
        />
        <Divider />
        <RightItem
          title="Balneário Camboriú, SC"
          subtitle={t("balneario.description")}
          image={balneario}
          time={t("balneario.time")}
        />
        <Divider />
        <LeftItem
          title="Blumenau, SC"
          subtitle={t("blumenau.description")}
          image={blumenau}
          time={t("blumenau.time")}
        />
        <Divider />
        <RightItem
          title="Garopaba, SC"
          subtitle={t("garopaba.description")}
          image={garopaba}
          time={t("garopaba.time")}
        />
        <Divider />
        <LeftItem
          title="Bombinhas, SC"
          subtitle={t("bombinhas.description")}
          image={bombinhas}
          time={t("bombinhas.time")}
        />
        <Divider />
        <RightItem
          title="Itapema, SC"
          subtitle={t("itapema.description")}
          image={itapema}
          time={t("itapema.time")}
        />
      </Container>
    </div>
  );
};

export default DestinationsComponent;
